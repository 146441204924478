import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, October 6th, we will host a nutrition seminar
starting at 11:00am.  This is free for our members but you are welcome
to invite friends.  Non members will only pay \\$25.  Topics covered will
include: `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Nutrient Timing`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Estimated Protein, Carbohydrate and Fat intake`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Meal Planning`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Eating for Performance & Recovery`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Positives and Negatives of Current Diet Trends`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      